import { Dispatch } from '@reduxjs/toolkit';
import { http } from '@services/http/http.service';
import { Query, QueryClient, QueryKey } from '@tanstack/react-query';

import { DEFAULT_PER_PAGE } from '@honestica/core-apps-common/constants';
import {
  DashboardSearchParams,
  DocumentsList,
  FeatureFlags,
  IntegrationRequestsList,
  IntegrationsDashboardType,
  SendingRequest,
  SendingRequestBundle,
  SendingRequestDto,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { fetchSendingRequest, fetchSendingRequests } from '@api';
import {
  DOCUMENTS_CACHE_QUERY_KEY,
  DOCUMENTS_CACHE_RETRY_COUNT,
  DOCUMENTS_CACHE_STALE_TIME,
  DOCUMENTS_PATH,
  DOCUMENT_CACHE_QUERY_KEY,
} from '@constants/documents.constants';
import { documentDtoToSendingRequest } from '@store/adapters';
import { updateEntitiesAction } from '@store/documents/documents.actions';
import {
  DocumentDashboardType,
  INITIAL_SEARCH_PARAMS,
  SendingRequestDtoFromType,
} from '@store/documents/documents.state';
import { EntitiesState } from '@store/entities/entities.state';

import { professionalToIdentityReference } from './identities.util';
import { parseSearchParams } from './search.util';

export const getDashboardDocumentsQueryKey = ({
  dashboardType,
  identity,
  search,
}: {
  dashboardType: DocumentDashboardType;
  identity?: string;
  search?: { searchParams: DashboardSearchParams; featureFlags: FeatureFlags };
}) => {
  const key = [
    DOCUMENTS_CACHE_QUERY_KEY,
    dashboardType,
    ...(identity && dashboardType === SendingsDashboardType.Draft ? [identity] : ['all']),
  ];

  if (search) {
    const searchParamsForQueryKey = prepareSearchParamsForCaching({
      searchParams: search.searchParams,
      currentIdentity: identity,
      dashboardType,
    });
    return [...key, searchParamsForQueryKey];
  }
  return key;
};

export const getDocumentQueryKey = (documentId: string | undefined) =>
  [DOCUMENT_CACHE_QUERY_KEY, ...(documentId ? [documentId] : [])] as const;

export type DocumentsListFromType<T> = T extends
  | IntegrationsDashboardType.Worklist
  | IntegrationsDashboardType.Integrated
  ? IntegrationRequestsList
  : DocumentsList;

/**
 * Query fn that fetch documents and update its entities in redux store
 */
export const documentsQueryFn = async ({
  searchParams,
  dispatchFn,
  currentIdentity,
  dashboardType,
}: {
  searchParams: DashboardSearchParams;
  dispatchFn: Dispatch<any>;
  currentIdentity?: string;
  featureFlags: FeatureFlags;
  dashboardType: SendingsDashboardType | IntegrationsDashboardType;
}) => {
  const preparedSearchParams = prepareSearchParamsForCaching({
    searchParams,
    currentIdentity,
    dashboardType,
  });
  const data = await fetchDocumentsHandler(preparedSearchParams);
  const { documents, ...entities } = data.entities;
  dispatchFn(updateEntitiesAction(entities));
  return data;
};

/**
 * Query fn that fetch sending requests and update its entities in redux store
 */
export const sendingRequestsQueryFn = async ({
  searchParams,
  dispatchFn,
  currentIdentity,
  dashboardType,
}: {
  searchParams: DashboardSearchParams;
  dispatchFn: Dispatch<any>;
  currentIdentity?: string;
  dashboardType: SendingsDashboardType | IntegrationsDashboardType;
}) => {
  const preparedSearchParams = prepareSearchParamsForCaching({
    searchParams,
    currentIdentity,
    dashboardType,
  });
  const data = await fetchSendingRequests(preparedSearchParams);
  const { documents, ...entities } = data.entities;
  dispatchFn(updateEntitiesAction(entities));
  return data;
};

/**
 * Function applied after selecting a documents list from a useQuery hook
 */
export const transformDocumentsList = (response: DocumentsList, entities: EntitiesState) => ({
  documents: response?.entities
    ? (response?.entities.documents ?? [])
        .filter(
          (document, index, docs) =>
            docs.findIndex((docToFilter) => docToFilter.id === document.id) === index,
        )
        .map((document) => documentDtoToSendingRequest(document, entities))
    : [],
  total: response.total,
});

/**
 * Query fn that fetch one document and update its entities in redux store
 */
export const documentQueryFn = async (
  id: SendingRequest['id'] | undefined,
  dashboardType: DocumentDashboardType,
  dispatchFn: Dispatch<any>,
) => {
  if (!id) {
    return undefined;
  }

  const data = await fetchDocumentHandler(id, dashboardType);
  const { ...entities } = data.entities;
  dispatchFn(updateEntitiesAction(entities));

  return data;
};

/**
 * Query fn that fetch one sending request and update its entities in redux store
 */
export const sendingRequestQueryFn = async ({
  id,
  dashboardType,
  dispatchFn,
}: {
  id: SendingRequest['id'] | undefined;
  dashboardType: DocumentDashboardType;
  dispatchFn: Dispatch<any>;
}) => {
  if (!id) {
    return undefined;
  }

  const data = await fetchSendingRequest(id, dashboardType);
  const { ...entities } = data.entities;
  dispatchFn(updateEntitiesAction(entities));

  return data;
};

/**
 * React Query handler to fetch documents
 */
export const fetchDocumentsHandler = (
  searchParams: DashboardSearchParams,
): Promise<DocumentsList> =>
  http.get({ path: DOCUMENTS_PATH, searchParams: parseSearchParams(searchParams) });

/**
 * React Query handler to fetch one document
 */
export const fetchDocumentHandler = (
  id: SendingRequest['id'],
  dashboardType: DocumentDashboardType,
): Promise<SendingRequestBundle> =>
  http.get({ path: `${DOCUMENTS_PATH}/${id}`, searchParams: { dashboardType } });

/**
 * Prepare searchParams before using them as query key or in fetching handler
 */
export const prepareSearchParamsForCaching = ({
  searchParams,
  currentIdentity,
  dashboardType,
}: {
  searchParams: DashboardSearchParams;
  currentIdentity?: string;
  dashboardType: DocumentDashboardType;
}): DashboardSearchParams => {
  let preparedSearchParams: DashboardSearchParams;
  switch (dashboardType) {
    case SendingsDashboardType.Draft:
      return prepareDraftSearchParamsForCaching({
        searchParams,
        currentIdentity,
      });
    case SendingsDashboardType.Sent:
      preparedSearchParams = prepareSentSearchParamsForCaching({ searchParams });
      break;
    case SendingsDashboardType.Archive:
      preparedSearchParams = {
        ...prepareArchiveSearchParamsForCaching({ searchParams }),
        dashboardType,
      };
      break;
    case SendingsDashboardType.Inbox:
      preparedSearchParams = {
        ...prepareInboxSearchParamsForCaching({ searchParams }),
        dashboardType,
      };
      break;

    default:
      preparedSearchParams = searchParams;
      break;
  }

  return preparedSearchParams;
};

const prepareDraftSearchParamsForCaching = ({
  searchParams,
  currentIdentity,
}: {
  searchParams: DashboardSearchParams;
  currentIdentity?: string;
}) => ({
  ...searchParams,
  ...(currentIdentity && { selectedSenderFilter: undefined }),
  ...(currentIdentity && {
    strictSender: currentIdentity,
  }), // replace senderfilter if identity dashboard
});

export const prepareSentSearchParamsForCaching = ({
  searchParams,
}: {
  searchParams: DashboardSearchParams;
}) => ({
  ...searchParams,
});

export const prepareArchiveSearchParamsForCaching = ({
  searchParams,
}: {
  searchParams: DashboardSearchParams;
}) => ({
  ...searchParams,
});

export const prepareInboxSearchParamsForCaching = ({
  searchParams,
}: {
  searchParams: DashboardSearchParams;
}) => ({
  ...searchParams,
});

// Functions using queryClient:
export const getDashboardDataInCache = <T extends DocumentDashboardType>({
  dashboardType,
  identity,
  queryClient,
  search,
}: {
  dashboardType: SendingsDashboardType;
  identity?: string;
  queryClient: QueryClient;
  search?: { searchParams: DashboardSearchParams; featureFlags: FeatureFlags };
}) =>
  queryClient.getQueryData(
    getDashboardDocumentsQueryKey({ dashboardType, identity, search }),
  ) as DocumentsListFromType<T>;

/**
 * Add given document in the specified draft cache
 */
export const addDraftDocumentInCache = ({
  newDocument,
  identity,
  searchParams = INITIAL_SEARCH_PARAMS,
  refetchType = 'inactive',
  queryClient,
  featureFlags,
}: {
  newDocument: SendingRequestDto;
  identity?: string;
  searchParams?: DashboardSearchParams;
  refetchType?: 'all' | 'active' | 'inactive' | 'none';
  queryClient: QueryClient;
  featureFlags: FeatureFlags;
}) => {
  const documentsInCache = getDashboardDataInCache<SendingsDashboardType>({
    dashboardType: SendingsDashboardType.Draft,
    identity,
    search: { searchParams, featureFlags },
    queryClient,
  });

  // Invalidate current queries
  queryClient.cancelQueries({
    queryKey: getDashboardDocumentsQueryKey({
      dashboardType: SendingsDashboardType.Draft,
      identity,
      search: { searchParams, featureFlags },
    }),
  });

  // If cache for current dashboard is empty, force query refetch
  if (documentsInCache === undefined || !documentsInCache.entities.documents.length) {
    queryClient.refetchQueries({
      queryKey: getDashboardDocumentsQueryKey({
        dashboardType: SendingsDashboardType.Draft,
        identity,
        search: { searchParams, featureFlags },
      }),
    });
    return;
  }

  // Add new document to current cache. Ensure to keep DEFAULT_PER_PAGE docs in the current page
  queryClient.setQueryData(
    getDashboardDocumentsQueryKey({
      dashboardType: SendingsDashboardType.Draft,
      identity,
      search: { searchParams, featureFlags },
    }),
    (currentDocuments: DocumentsList) =>
      currentDocuments
        ? {
            ...currentDocuments,
            entities: {
              ...currentDocuments.entities,
              documents: [
                newDocument,
                ...(currentDocuments.entities.documents.length >= DEFAULT_PER_PAGE
                  ? currentDocuments.entities.documents.slice(0, -1)
                  : currentDocuments.entities.documents),
              ],
            },
          }
        : undefined,
  );

  // Invalidate all queries of next pages that already exists in cache
  queryClient.invalidateQueries(
    {
      queryKey: getDashboardDocumentsQueryKey({
        dashboardType: SendingsDashboardType.Draft,
        identity,
      }),
      refetchType,
      predicate: (query) => ((query.queryKey[3] as DashboardSearchParams)?.page as number) > 1,
      exact: false,
    },
    { cancelRefetch: false },
  );
};

/**
 * Update given document in the specified cache
 */
export const updateDocumentInCache = ({
  updatedDocument,
  dashboardType = SendingsDashboardType.Draft,
  queryClient,
}: {
  updatedDocument: SendingRequestDto;
  dashboardType?: SendingsDashboardType;
  queryClient: QueryClient;
}) => {
  const identity =
    dashboardType === SendingsDashboardType.Draft
      ? professionalToIdentityReference(updatedDocument.sender)
      : undefined;
  const updater = (currentDocuments: DocumentsList) =>
    currentDocuments
      ? {
          ...currentDocuments,
          entities: {
            ...currentDocuments.entities,
            documents: [
              ...currentDocuments.entities.documents.map((doc) =>
                doc.id === updatedDocument.id ? updatedDocument : doc,
              ),
            ],
          },
        }
      : undefined;

  queryClient.setQueriesData(
    {
      queryKey: getDashboardDocumentsQueryKey({ dashboardType, identity }),
      exact: false,
    },
    updater,
  );

  if (dashboardType === SendingsDashboardType.Draft) {
    queryClient.setQueriesData(
      {
        queryKey: getDashboardDocumentsQueryKey({ dashboardType: SendingsDashboardType.Draft }),
        exact: false,
      },
      updater,
    );
  }
};

/**
 * Remove given document from the specified draft cache
 */
export const removeDraftDocumentInCache = ({
  documentId,
  identity,
  queryClient,
}: {
  documentId: string;
  identity?: string;
  queryClient: QueryClient;
}) => {
  queryClient.setQueriesData(
    {
      queryKey: getDashboardDocumentsQueryKey({
        dashboardType: SendingsDashboardType.Draft,
        identity,
      }),
    },
    (currentDocuments: DocumentsList) => ({
      ...currentDocuments,
      entities: {
        ...currentDocuments.entities,
        documents: [...currentDocuments.entities.documents.filter((doc) => doc.id !== documentId)],
      },
    }),
  );
};

/**
 * Invalidate cache that match given params
 */
export const invalidateDashboardCache = ({
  dashboardType,
  identity,
  type = undefined,
  refetchType = 'inactive',
  search,
  predicate,
  exact,
  queryClient,
}: {
  dashboardType: SendingsDashboardType;
  identity?: string;
  search?: { searchParams: DashboardSearchParams; featureFlags: FeatureFlags };
  type?: 'all' | 'active' | 'inactive';
  refetchType?: 'all' | 'active' | 'inactive' | 'none';
  predicate?: (query: Query<unknown, unknown, unknown, QueryKey>) => boolean;
  exact?: boolean;
  queryClient: QueryClient;
}) => {
  queryClient.invalidateQueries(
    {
      queryKey: getDashboardDocumentsQueryKey({ dashboardType, identity, search }),
      type,
      refetchType,
      predicate,
      exact,
    },
    { cancelRefetch: false },
  );
};

export const getDocumentsInCache = <T extends DocumentDashboardType>({
  queryClient,
  dashboardType,
  identity,
  search,
}: {
  dashboardType: T;
  search?: { searchParams: DashboardSearchParams; featureFlags: FeatureFlags };
  identity?: string;
  queryClient: QueryClient;
}) =>
  queryClient.getQueryData<DocumentsList>(
    getDashboardDocumentsQueryKey({
      dashboardType,
      identity,
      search,
    }),
  )?.entities?.documents;

/**
 * Find first occurence of document with given ID in cache
 */
export const findDocumentInCache = <T extends DocumentDashboardType>({
  dashboardType,
  identity,
  search,
  documentId,
  queryClient,
}: {
  dashboardType: T;
  search?: { searchParams: DashboardSearchParams; featureFlags: FeatureFlags };
  identity?: string;
  documentId: string;
  queryClient: QueryClient;
}) => {
  const currentDashboardDocumentsInCache = getDocumentsInCache({
    queryClient,
    dashboardType,
    identity,
    search,
  });
  return (currentDashboardDocumentsInCache as SendingRequestDtoFromType<T>[])?.find(
    (doc) => doc.id === documentId,
  );
};

/**
 * Find all occurences of document with given ID in cache
 */
export const findDocumentsInCache = ({
  dashboardType,
  identity,
  search,
  documentId,
  queryClient,
}: {
  dashboardType: SendingsDashboardType;
  search?: { searchParams: DashboardSearchParams; featureFlags: FeatureFlags };
  identity?: string;
  documentId: string;
  queryClient: QueryClient;
}) => {
  const queryKey = getDashboardDocumentsQueryKey({ dashboardType, identity, search });
  const currentQueriesCache = queryClient.getQueriesData<DocumentsList>(queryKey);
  return currentQueriesCache.reduce(
    (documentsInCache: SendingRequestDto[], query: [QueryKey, DocumentsList | undefined]) => {
      const docInCache = query[1]?.entities?.documents?.find((doc) => doc.id === documentId);
      return docInCache ? [...documentsInCache, docInCache] : documentsInCache;
    },
    [],
  );
};

/**
 * Invalidate all draft caches with given identity
 */
export const refreshDraftDocumentsInCache = ({
  identity,
  queryClient,
}: {
  identity: string | undefined;
  queryClient: QueryClient;
}) => {
  queryClient.invalidateQueries({
    queryKey: getDashboardDocumentsQueryKey({
      dashboardType: SendingsDashboardType.Draft,
      identity,
    }),
    refetchType: 'active',
  });
};

/**
 * Find document ID in cache and invalidate corresponding caches
 */
export const refreshDraftCacheAfterActionOnOneDocument = ({
  documentId,
  currentIdentity,
  search,
  queryClient,
}: {
  documentId: string;
  currentIdentity?: string;
  search?: { searchParams: DashboardSearchParams; featureFlags: FeatureFlags };
  queryClient: QueryClient;
}) => {
  const documentsInCache = findDocumentsInCache({
    dashboardType: SendingsDashboardType.Draft,
    documentId,
    identity: currentIdentity,
    search,
    queryClient,
  });

  const identity =
    documentsInCache.length > 0
      ? professionalToIdentityReference(documentsInCache[0].sender)
      : currentIdentity;

  if (identity) {
    refreshDraftDocumentsInCache({ identity, queryClient });
  }
  refreshDraftDocumentsInCache({ identity: 'all', queryClient });

  return { documentsInCache, identity };
};

/**
 * Find document IDs in cache and invalidate corresponding caches
 */
export const refreshDraftCacheAfterActionOnManyDocuments = ({
  documentIds,
  currentIdentity,
  search,
  queryClient,
}: {
  documentIds: string[];
  currentIdentity?: string;
  search?: { searchParams: DashboardSearchParams; featureFlags: FeatureFlags };
  queryClient: QueryClient;
}) => {
  const identities: string[] = [];
  let allDocumentsInCache: SendingRequestDto[] = [];

  for (const documentId of documentIds) {
    const documentsInCache = findDocumentsInCache({
      dashboardType: SendingsDashboardType.Draft,
      documentId: `${documentId}`,
      identity: currentIdentity ?? 'all',
      search,
      queryClient,
    });

    const documentIdentity =
      documentsInCache?.length > 0
        ? professionalToIdentityReference(documentsInCache[0].sender)
        : currentIdentity;

    if (documentIdentity && !identities.find((identity) => identity === documentIdentity)) {
      identities.push(documentIdentity);
    }

    allDocumentsInCache = [...allDocumentsInCache, ...documentsInCache];
  }

  for (const identity of identities) {
    refreshDraftDocumentsInCache({ identity, queryClient });
  }

  refreshDraftDocumentsInCache({ identity: 'all', queryClient });

  return { documentsInCache: allDocumentsInCache, identities };
};

/**
 * Prefetch first page of given dashboard type
 */
export const prefetchDocuments = async ({
  dashboardType,
  searchParams,
  dispatchFn,
  currentIdentity,
  queryClient,
  featureFlags,
}: {
  dashboardType: SendingsDashboardType | IntegrationsDashboardType;
  searchParams: DashboardSearchParams;
  dispatchFn: Dispatch<any>;
  currentIdentity?: string;
  queryClient: QueryClient;
  featureFlags: FeatureFlags;
}) => {
  await queryClient.prefetchQuery({
    queryKey: getDashboardDocumentsQueryKey({
      dashboardType,
      identity: currentIdentity,
      search: { searchParams, featureFlags },
    }),
    queryFn: () =>
      documentsQueryFn({ searchParams, currentIdentity, dashboardType, featureFlags, dispatchFn }),
    staleTime: DOCUMENTS_CACHE_STALE_TIME,
    retry: DOCUMENTS_CACHE_RETRY_COUNT,
  });
};

/**
 * Prefetch first page of sending request dashboard type
 */
export const prefetchSendingRequests = async ({
  dashboardType,
  searchParams,
  dispatchFn,
  currentIdentity,
  queryClient,
  featureFlags,
}: {
  dashboardType: SendingsDashboardType | IntegrationsDashboardType;
  searchParams: DashboardSearchParams;
  dispatchFn: Dispatch<any>;
  currentIdentity?: string;
  queryClient: QueryClient;
  featureFlags: FeatureFlags;
}) => {
  await queryClient.prefetchQuery({
    queryKey: getDashboardDocumentsQueryKey({
      dashboardType,
      identity: currentIdentity,
      search: { searchParams, featureFlags },
    }),
    queryFn: () =>
      sendingRequestsQueryFn({
        searchParams,
        currentIdentity,
        dashboardType,
        dispatchFn,
      }),
    staleTime: DOCUMENTS_CACHE_STALE_TIME,
    retry: DOCUMENTS_CACHE_RETRY_COUNT,
  });
};

/**
 * Delay refresh of sent dashboard cache (doc must have been sent before)
 */
export const delaySentDashboardCacheRefresh = ({ queryClient }: { queryClient: QueryClient }) =>
  setTimeout(() => {
    invalidateDashboardCache({
      dashboardType: SendingsDashboardType.Sent,
      refetchType: 'active',
      exact: false,
      queryClient,
    });
  }, 3000);

// TODO: add unit test when possible
