import { replaceIntegrationRequestEncounter } from '@lifen-integration/features/encounters/services';
import { logger } from '@services/logger/logger.service';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { IntegrationRequestDto } from '@honestica/core-apps-common/types';

import { SwitchEncounterAction } from '@store/documents/documents.actions';
import { selectIntegrationRequestDto } from '@store/documents/integrations/worklist.selector';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';

function* switchEncounterAsync({ payload: { integrationId, encounter } }: SwitchEncounterAction) {
  const docDto: IntegrationRequestDto = yield select(selectIntegrationRequestDto);
  try {
    yield replaceIntegrationRequestEncounter(docDto.id, integrationId, encounter);
    yield put(WorklistIntegrationsActions.switchEncounterSuccess({ id: docDto.id, encounter }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to replace Encounter', error);
    yield put(
      WorklistIntegrationsActions.switchEncounterFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

export function* encountersIntegrationRequestsSaga() {
  yield all([takeLatest(WorklistIntegrationsActions.switchEncounter.type, switchEncounterAsync)]);
}
