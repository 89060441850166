import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { NavigateFunction } from 'react-router-dom';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import {
  DashboardSearchParams,
  FeatureFlags,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { sendDocument, sendDocuments } from '@api';
import { DRAFT, DRAFT_PATH } from '@constants/documents.constants';
import {
  ACTION_DEFERRED_DELAY,
  ActionDeferredActions,
} from '@store/actionDeferred/actionsDeferred.slice';
import { DeferredActionsType } from '@store/actionDeferred/actionsDeferred.state';
import {
  selectDashboardSearchParams,
  selectManyDocumentsFileStorageIds,
} from '@store/documents/documents.selector';
import { selectDraftCurrentIdentityReference } from '@store/documents/outgoing/draft.selector';
import { removeFromSelection, removeManyFromSelection } from '@store/selection/selection.action';
import { getFeatureFlags } from '@store/user/user.selector';
import {
  delaySentDashboardCacheRefresh,
  refreshDraftCacheAfterActionOnManyDocuments,
  refreshDraftCacheAfterActionOnOneDocument,
} from '@utils/caching.util';
import {
  identityReferenceToUrlIdentity,
  professionalToIdentityReference,
} from '@utils/identities.util';

import {
  BatchAction,
  BatchDeferredAction,
  OneAction,
  OneDeferredAction,
} from '../../documents.actions';
import { DraftDocumentsActions } from '../draft.slice';

function* sendOneDocumentSaga({ payload: { id, deferredId } }: OneDeferredAction) {
  const queryClient: QueryClient = yield getContext('queryClient');
  const featureFlags: FeatureFlags = yield select(getFeatureFlags);
  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, [id], DRAFT);
  const currentIdentity: string | undefined = yield select((state) =>
    selectDraftCurrentIdentityReference(state),
  );
  const searchParams: DashboardSearchParams = yield select((state) =>
    selectDashboardSearchParams(state, DRAFT),
  );
  try {
    yield sendDocument(id);
    yield put(DraftDocumentsActions.sendOneSuccess({ id, fileStorageIds, deferredId }));
    const { identity } = refreshDraftCacheAfterActionOnOneDocument({
      documentId: id,
      currentIdentity,
      search: { searchParams, featureFlags },
      queryClient,
    });

    // We must wait for document to be "really" sent
    delaySentDashboardCacheRefresh({ queryClient });

    yield put(
      DraftDocumentsActions.decrementDraftCounter({
        identity,
        count: 1,
      }),
    );

    logger.info('LOGIC', `Document n°${id} was successfully sent`);
  } catch (error) {
    logger.error('LOGIC', 'Failed to send document', error);
    yield put(
      DraftDocumentsActions.sendOneFailure({
        id,
        error,
      }),
    );
  }
}

function* sendOneDocumentDeferredSaga({ payload: { id } }: OneAction) {
  const navigate: NavigateFunction = yield getContext('customNavigate');
  const currentIdentity: string | undefined = yield select((state) =>
    selectDraftCurrentIdentityReference(state),
  );
  const path = currentIdentity
    ? `${DRAFT_PATH}/${identityReferenceToUrlIdentity(currentIdentity)}`
    : DRAFT_PATH;
  yield all([
    put(
      ActionDeferredActions.addOne({
        type: DeferredActionsType.sendDocument,
        args: { id },
        delay: ACTION_DEFERRED_DELAY,
        id: uuid(),
      }),
    ),
    put(removeFromSelection({ dashboard: SendingsDashboardType.Draft, id })),
    navigate(`${path}?refresh=false`), // dashboard refresh is made after the document is really sent
  ]);
}

function* sendManyDocumentsSaga({ payload: { ids, deferredId } }: BatchDeferredAction) {
  const queryClient: QueryClient = yield getContext('queryClient');
  const featureFlags: FeatureFlags = yield select(getFeatureFlags);
  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, ids, DRAFT);
  const currentIdentity: string | undefined = yield select((state) =>
    selectDraftCurrentIdentityReference(state),
  );
  try {
    yield call(sendDocuments, ids);
    yield put(DraftDocumentsActions.sendManySuccess({ ids, fileStorageIds, deferredId }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to send documents', error);
    yield put(
      DraftDocumentsActions.sendManyFailure({
        ids,
        error,
      }),
    );
  } finally {
    const searchParams: DashboardSearchParams = yield select((state) =>
      selectDashboardSearchParams(state, DRAFT),
    );
    const { documentsInCache } = refreshDraftCacheAfterActionOnManyDocuments({
      documentIds: ids,
      currentIdentity,
      search: { searchParams, featureFlags },
      queryClient,
    });

    // We must wait for document to be "really" sent
    delaySentDashboardCacheRefresh({ queryClient });

    // Decrement draft counter for each document's identity
    for (const documentInCache of documentsInCache) {
      yield put(
        DraftDocumentsActions.decrementDraftCounter({
          identity: professionalToIdentityReference(documentInCache.sender),
          count: 1,
        }),
      );
    }
    yield put(DraftDocumentsActions.flushLoadingDocumentIds);
  }
}

function* sendManyDocumentsDeferredSaga(action: BatchAction) {
  yield put(DraftDocumentsActions.updateLoadingDocumentIds(action.payload));
  yield put(
    ActionDeferredActions.addOne({
      type: DeferredActionsType.sendMany,
      args: { ids: action.payload.ids },
      delay: ACTION_DEFERRED_DELAY,
      id: uuid(),
    }),
  );
  yield put(
    removeManyFromSelection({
      dashboard: SendingsDashboardType.Draft,
      ids: action.payload.ids,
    }),
  );
}

export function* sendDraftDocumentsSagas() {
  yield all([
    takeLatest(DraftDocumentsActions.sendOne.type, sendOneDocumentSaga),
    takeLatest(DraftDocumentsActions.sendOneDeferred.type, sendOneDocumentDeferredSaga),
    takeLatest(DraftDocumentsActions.sendMany.type, sendManyDocumentsSaga),
    takeLatest(DraftDocumentsActions.sendManyDeferred.type, sendManyDocumentsDeferredSaga),
  ]);
}
