import { http } from '@services/http/http.service';

import { BodyType, Encounter } from '@honestica/core-apps-common/types';

export async function getIntegrationRequestEncounters(documentId: string, integrationId: string) {
  return await http.get({
    path: `/integrations/${documentId}/integrations/${integrationId}/encounters`,
  });
}

export async function replaceIntegrationRequestEncounter(
  documentId: string,
  integrationId: string,
  encounter: Encounter,
) {
  return await http.put({
    path: `/integrations/${documentId}/integrations/${integrationId}/encounters`,
    body: encounter as unknown as BodyType,
  });
}
