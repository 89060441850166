import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { all, getContext, put, select, takeLatest } from 'redux-saga/effects';

import {
  DashboardSearchParams,
  FeatureFlags,
  SendingRequestBundle,
  SendingRequestDto,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import * as API from '@api';
import { INBOX, SENT } from '@constants/documents.constants';
import { updateEntitiesAction } from '@store/documents/documents.actions';
import {
  selectDashboardSearchParams,
  selectDetailDocumentId,
} from '@store/documents/documents.selector';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { SentDocumentsActions } from '@store/documents/outgoing/sent.slice';
import {
  RealtimeCommunicationRequestUpdated,
  realtimeCommunicationUpdated,
} from '@store/externalEvents/externalEvents.action';
import { selectPathName } from '@store/router/router.selector';
import { getFeatureFlags } from '@store/user/user.selector';
import {
  findDocumentInCache,
  invalidateDashboardCache,
  updateDocumentInCache,
} from '@utils/caching.util';
import { getDashboardTypeFromRoute } from '@utils/route.utils';

import { DocumentDashboardType, SendingRequestsActions } from '../documents/documents.state';

const Actions: Record<
  SendingsDashboardType.Inbox | SendingsDashboardType.Sent,
  SendingRequestsActions
> = {
  [INBOX]: InboxDocumentsActions,
  [SENT]: SentDocumentsActions,
};

function receiveSendingUpdated() {
  return function* saga({ payload }: ReturnType<RealtimeCommunicationRequestUpdated>) {
    const queryClient: QueryClient = yield getContext('queryClient');

    const documentId = payload.resource.id;

    logger.info('LOGIC', `[Sending Realtime] refreshing sending with id: ${documentId}`);

    if (!documentId) return;

    const pathname: string = yield select(selectPathName);
    const currentDashboardType = getDashboardTypeFromRoute(pathname);
    const flags: FeatureFlags = yield select(getFeatureFlags);

    if (!currentDashboardType) {
      return;
    }

    try {
      if (
        currentDashboardType !== SendingsDashboardType.Inbox &&
        currentDashboardType !== SendingsDashboardType.Sent
      ) {
        // Invalidate inbox and sent cache without immediate refetch
        invalidateDashboardCache({
          dashboardType: SendingsDashboardType.Sent,
          type: 'all',
          refetchType: 'none',
          queryClient,
        });
        invalidateDashboardCache({
          dashboardType: SendingsDashboardType.Inbox,
          type: 'all',
          refetchType: 'none',
          queryClient,
        });
        return;
      }

      const searchParams: DashboardSearchParams = yield select((state) =>
        selectDashboardSearchParams(state, currentDashboardType),
      );

      const selectedId: string = yield select(selectDetailDocumentId, currentDashboardType);
      const sendingDtoFromCache = findDocumentInCache<DocumentDashboardType>({
        dashboardType: currentDashboardType,
        search: { searchParams, featureFlags: flags },
        documentId,
        queryClient,
      }) as SendingRequestDto | undefined;

      // Sending was found in cache
      if (sendingDtoFromCache) {
        const bundle: SendingRequestBundle = yield API.fetchSendingRequest(
          documentId,
          currentDashboardType,
        );

        yield updateDocumentInCache({
          updatedDocument: bundle.document,
          dashboardType: currentDashboardType,
          queryClient,
        });
        yield put(updateEntitiesAction(bundle.entities));

        // refresh sending request dto in detail view store
        if (documentId === selectedId) {
          yield put(Actions[currentDashboardType].setDetailViewDocumentDto(bundle.document));
        }
      } else {
        // Sending was not found, invalidate all cache from other dashboard without immediate refetch
        invalidateDashboardCache({
          dashboardType:
            currentDashboardType === SendingsDashboardType.Inbox
              ? SendingsDashboardType.Sent
              : SendingsDashboardType.Inbox,
          type: 'all',
          refetchType: 'none',
          queryClient,
        });
        // Invalidate active cache for current dashboard with background fetching
        invalidateDashboardCache({
          dashboardType: currentDashboardType,
          type: 'all',
          refetchType: 'active',
          queryClient,
        });
      }
    } catch (err) {
      logger.error('LOGIC', '[Sending Realtime] Failed to refresh sending', err);
    }
  };
}

export function* sendingRealTimeSagas() {
  yield all([takeLatest(realtimeCommunicationUpdated.type, receiveSendingUpdated())]);
}
