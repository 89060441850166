import { CounterWithIdentitiesType, DocumentType, TelecomType } from '..';
import { UserSettings } from '../auth/user.type';

import { Address } from './address';
import { Attachment } from './attachment';
import { Integration } from './integration.types';
import { Organization } from './organization';
import { Patient } from './patient.types';
import { Practitioner } from './practitioner';
import { PractitionerRole } from './practitionerRole';
import { Professional, ProfessionalBase, ProfessionalDto } from './professional';
import {
  DocumentRecipientBundle,
  DocumentRecipientDto,
  ExtractedGlobalPredictions,
  MediumList,
} from './recipient';
import { Telecom } from './telecom';

export enum DocumentJobError {
  WrongFileType = 'WRONG_FILE_TYPE',
  FileSizeTooBig = 'FILE_SIZE_TOO_BIG',
  TooManyPages = 'TOO_MANY_PAGES',
  IsNotA4 = 'IS_NOT_A4',
  IsNotA3 = 'IS_NOT_A3',
  CorruptedFile = 'CORRUPTED_FILE',
  UploadFailed = 'UPLOAD_FAILED',
  SuspiciousFile = 'SUSPICIOUS_FILE',
  Default = 'DEFAULT',
}

export enum DocumentJobStatus {
  Pending = 'PENDING' /* Job created. Waiting to be processed */,
  Processing = 'PROCESSING' /* Job processing. Job is uploading */,
  UserCancelled = 'USER_CANCELLED' /* Cancelled by user before upload */,
  Failed = 'FAILED' /* Invalid format, or failed to create resource on server */,
  Processed = 'PROCESSED' /* Successfully created on server */,
}

export type UploadSource = 'opening' | 'watcher' | 'button' | 'drop';

export type FlowType = 'integration' | 'sending';

/**
 * The result of a file being uploaded by the user,
 * generates a Document when status is 'PROCESSED'
 */
export type UploadDocumentJob = {
  status: DocumentJobStatus;
  uploadSource: UploadSource;
  startDate: Date;
  sender: Professional;
  id: string;
  filePath: string;
  fileStorageId: string;
  fileName: string;
  errors?: DocumentJobError[];
  documentId?: SendingRequest['id'];
  flowType: FlowType;
};

export enum DocumentStatus {
  Draft = 'draft',
  Active = 'active',
  Suspended = 'suspended',
  Cancelled = 'cancelled',
  Completed = 'completed',
  EnteredInError = 'entered-in-error',
}

/**
 * A set of tags that will impact a Document's
 * processing throughout Lifen systems
 */

// TODO move all tag handling to server side
export enum DocumentTag {
  ActivatedByDesktop = 'DESKTOP-APP',
  ActivatedByWeb = 'WEB-APP',
  DematPatientSendingProhibited = 'DEMAT_PATIENT_SENDING_PROHIBITED',
  DematPatientWithout2faProhibited = 'DEMAT_PATIENT_WITHOUT_2FA_PROHIBITED',
  Desktop = 'DESKTOP',
  ExtractedWithoutOCR = 'EXTRACTED_WITHOUT_OCR',
  ImmediateMode = 'IMMEDIATE_MODE',
  Import = 'IMPORT',
  InternalSendingProhibited = 'INTERNAL_SENDING_PROHIBITED',
  InternalSendingProhibitedIfPostal = 'INTERNAL_SENDING_PROHIBITED_IF_POSTAL',
  MainRecipientOnly = 'MAIN_RECIPIENT_ONLY',
  NeedsOcr = 'NEEDS_OCR',
  NeedSplit = 'NEED_SPLIT',
  NoPatientResend = 'NONE',
  PatientResendIfBounce = 'BOUNCE',
  PatientResendIfBounceOrUnread = 'BOUNCE_OR_UNREAD',
  PatientSendingProhibited = 'PATIENT_SENDING_PROHIBITED',
  ProcessedByIrene = 'PROCESSED',
  ProcessingOcr = 'PROCESSING_OCR',
  RecipientVerified = 'RECIPIENT_VERIFIED',
  SenderVerified = 'SENDER_VERIFIED',
  SendingProhibited = 'SENDING_PROHIBITED',
  V5 = 'V5',
  DmpUnavailable = 'DMP_UNAVAILABLE',
}

/**
 * Whether the document requires additionnal
 * verification from the user
 */

export enum DocumentVerificationStatus {
  ReadyToSend = 'READY_TO_SEND',
  ReadyToImport = 'READY_TO_IMPORT',
  UserVerificationNeeded = 'USER_VERIFICATION_NEEDED',
  UserCorrectionNeeded = 'USER_CORRECTION_NEEDED',
  Waiting = 'WAITING',
  Sending = 'SENDING',
  Integrating = 'INTEGRATING',
  Sent = 'SENT',
  Error = 'ERROR',
  Downgraded = 'DOWNGRADED_MODE',
}

export enum DocumentVerificationTypes {
  ai_not_available = 'ai-not-available',
  dmp_not_available = 'dmp-not-available',
  email_telecom_without_mfa = 'email-telecom-without-mfa',
  integration_missing = 'integration-missing',
  integration_recipient_missing = 'integration-recipient-missing',
  no_successful_match_bundles = 'no-successful-match-bundles', // deprecated?
  patient_missing = 'patient-missing',
  possible_mix_first_last = 'possible-mix-first-last',
  recipients_missing = 'recipients-missing',
  recipients_other_than_high = 'recipients-other-than-high',
  recipients_without_medium = 'recipients-without-medium',
  search_gateway_not_available = 'search-gateway-not-available',
}
export enum DocumentVerificationV2Types {
  ai_not_available = 'ai-not-available',
  dmp_not_available = 'dmp-not-available',
  integration_missing = 'integration-missing',
  integration_recipient_missing = 'integration-recipient-missing',
  no_successful_match_bundles = 'no-successful-match-bundles', // deprecated?
  patient_missing = 'patient-missing',
  recipients_missing = 'recipients-missing',
  search_gateway_not_available = 'search-gateway-not-available',
  recipients_other_than_high = 'recipients-other-than-high',
}

export enum PatientVerificationTypes {
  email_telecom_without_mfa = 'email-telecom-without-mfa',
  possible_mix_first_last = 'possible-mix-first-last',
}

export type DocumentVerification =
  | DocumentVerificationTypes.ai_not_available
  | DocumentVerificationTypes.dmp_not_available
  | DocumentVerificationTypes.email_telecom_without_mfa // Patient recipient
  | DocumentVerificationTypes.integration_missing
  | DocumentVerificationTypes.integration_recipient_missing // deprecated
  | DocumentVerificationTypes.no_successful_match_bundles // deprecated?
  | DocumentVerificationTypes.patient_missing // not use anymore (except in server)
  | DocumentVerificationTypes.possible_mix_first_last // Patient
  | DocumentVerificationTypes.recipients_missing
  | DocumentVerificationTypes.recipients_other_than_high // Recipient
  | DocumentVerificationTypes.recipients_without_medium // Recipient
  | DocumentVerificationTypes.search_gateway_not_available;

type VerifiableType = 'Document' | 'Patient' | 'Recipient'; // Prediction? // use common type with recipients
export interface DocumentVerificationV2 {
  id: number;
  key: DocumentVerificationV2Types;
  verifiableId: number;
  verifiableType: VerifiableType;
  dismissedAt?: string;
}
export interface PatientVerification {
  id: number;
  key: PatientVerificationTypes;
  verifiableId: number;
  verifiableType: VerifiableType;
  dismissedAt?: string;
}

/**
 * Confidence level from AI (Irene)
 */
export type PredictionCertainty = 'possible' | 'probable' | 'certain';

export interface PatientPrediction {
  id: Patient['id'];
  predictionCertainty: PredictionCertainty;
}

export type RecipientId = ProfessionalBase['id'] | Patient['id'];

export interface RecipientPrediction {
  id: RecipientId;
  predictionCertainty?: PredictionCertainty;
}

export interface DocumentPatientDto {
  id: Patient['id'];
  ehrPatientId?: Patient['id'];
  telecoms: MediumList<Telecom>;
  addresses: MediumList<Address>;
  // verifications: PatientVerification[]; // todo
}

export type DocumentPatient = Omit<DocumentPatientDto, 'id' | 'ehrPatientId'> & {
  patient: Patient;
  ehrPatient?: Patient;
};

export interface DocumentBase {
  authoredOn: string;
  endPeriod?: string;
  id: string;
  versionId: string;
  integration: Integration | undefined;
  requesterId: string;
  startPeriod?: string;
  status: DocumentStatus;
  verificationStatus: DocumentVerificationStatus;
  documentDuplicated?: string;
  readOnly?: boolean;
}

/**
 * Created from a `DocumentJob` after it has been processed.
 */
export interface SendingRequestBase extends DocumentBase {
  documentType?: DocumentType;
  documentsMerged?: string[];
  documentReplaced?: string;
  predictions?: {
    globalConcerns?: ExtractedGlobalPredictions;
    moreSuggestedRecipients?: boolean;
  };
  hasBeenForwarded?: boolean;
  telecomAutomaticResendingFrom?: TelecomType;
  automaticallyResendingTo?: string;
  received?: string;
  message?: { subject?: string; html?: string; plain?: string };
  isSplit?: boolean;
  isReadOnly: boolean;
  verifications: DocumentVerification[];
  verificationsV2: DocumentVerificationV2[];
}

export interface SendingRequest extends SendingRequestBase {
  documentType?: DocumentType;
  sender: Professional;
  patient?: DocumentPatient;
  recipients: DocumentRecipientBundle[];
}

export interface SendingRequestDto extends SendingRequestBase {
  sender: ProfessionalDto;
  patient?: DocumentPatientDto;
  recipients: DocumentRecipientDto[];
}

export type NewDocumentPayload = Omit<
  SendingRequestBase,
  | 'authoredOn'
  | 'documentType'
  | 'id'
  | 'integration'
  | 'status'
  | 'verificationStatus'
  | 'verifications'
  | 'verificationsV2'
  | 'versionId'
> & {
  sender: Professional;
  file: File;
  flowType: FlowType;
  /**
   * Monkeypath to fix https://honestica.atlassian.net/browse/CORE-3149
   * @todo please delete this after the creation of alphonse /v1/users/{id}/settings endpoint
   * don't forget to delete userSettings property in packages/server/api/validators/schemas/Document.json
   */
  userSettings: UserSettings;
};

export interface SendingRequestBundle {
  document: SendingRequestDto;
  attachments?: Attachment[]; // TODO: delete
  entities: {
    patients: Patient[];
    organizations: Organization[];
    practitioners: Practitioner[];
    practitionerRoles: PractitionerRole[];
    attachments?: Attachment[];
  };
}

export enum SendingsDashboardType {
  Draft = 'draft',
  Inbox = 'inbox',
  Sent = 'sent',
  Archive = 'archive',
}

export const isSendingDashboardType = (val: unknown): val is SendingsDashboardType =>
  typeof val === 'string' &&
  Object.values(SendingsDashboardType).includes(val as SendingsDashboardType);

export const isSendingRequestDto = (val: unknown): val is SendingRequestDto =>
  (val as SendingRequest)?.recipients !== undefined;

export type FhirEntries = {
  patients: Patient[];
  practitioners: Practitioner[];
  organizations: Organization[];
};

export type CounterReadyToSend = CounterWithIdentitiesType;
export type CounterDraft = CounterWithIdentitiesType;
