import { http } from '@services/http/http.service';

import {
  Attachment,
  BodyType,
  Connector,
  CounterDraft,
  CounterReadyToSend,
  CreateDematRecipient,
  CreatePatient,
  CreatePostalRecipient,
  DashboardSearchParams,
  DmpConnectorSettings,
  DocumentConnector,
  DocumentPatientDto,
  DocumentRecipientDto,
  DocumentsList,
  DocumentsPreview,
  EhrPatientIntegration,
  Encounter,
  Integration,
  IntegrationMode,
  List,
  NewDocumentPayload,
  Patient,
  SendingRequest,
  SendingRequestBundle,
  SendingRequestDto,
  SendingRequestUpdateOperation,
  SendingsDashboardType,
  SuggestedRecipients,
  UserSettings,
} from '@honestica/core-apps-common/types';

import { RemoveVerificationPayload, ToggleConnector } from '@store/documents/documents.actions';
import { DocumentDashboardType } from '@store/documents/documents.state';
import { parseSearchParams } from '@utils/search.util';

const path = '/documents';

// UPLOAD

export async function createDocument(document: NewDocumentPayload): Promise<SendingRequestBundle> {
  const formData = new FormData();
  const requestBody: Record<string, any> = {};

  for (const [key, value] of Object.entries(document)) {
    if (key === 'file') {
      if (document.file) formData.append('file', document.file, document.file.name);
    } else {
      requestBody[key] = value;
    }
  }

  formData.append('document', JSON.stringify(requestBody));

  return await http.postMultiFormData({
    path: `${path}`,
    body: formData,
  });
}

// COMMON

export async function fetchDocumentAttachments(
  id: SendingRequest['id'],
  dashboardType: SendingsDashboardType,
): Promise<Attachment[]> {
  return await http.get({ path: `${path}/${id}/attachments`, searchParams: { dashboardType } });
}

// OUTBOX DASHBOARDS

const outboxIncludes = {
  includeEhrFallback: true,
  includePractitionerRoles: true,
  includeIntegration: true,
  includeConnectors: true,
};

const draftIncludes = {
  ...outboxIncludes,
  includePredictions: true,
  includeUnselectedConnectors: true,
};

const sentIncludes = {
  ...outboxIncludes,
  includePredictions: true,
  includeDeliveryStatuses: true,
};

export const fetchSendingRequests = (searchParams: DashboardSearchParams): Promise<DocumentsList> =>
  http.get({ path: '/sending_requests', searchParams: parseSearchParams(searchParams) });

export const fetchSendingRequest = (
  id: SendingRequest['id'],
  dashboardType: DocumentDashboardType,
): Promise<SendingRequestBundle> => {
  let searchParams: DashboardSearchParams = {
    ...outboxIncludes,
  };

  switch (dashboardType) {
    case SendingsDashboardType.Draft:
      searchParams = {
        ...draftIncludes,
      };
      break;
    case SendingsDashboardType.Sent:
      searchParams = {
        ...sentIncludes,
      };
      break;
    default:
      break;
  }

  return http.get({
    path: `/sending_requests/${id}`,
    searchParams: parseSearchParams(searchParams),
  });
};

// DRAFT

// Recipients

export async function addRecipientToDocument(
  docId: SendingRequest['id'],
  recipient: DocumentRecipientDto,
): Promise<SendingRequestBundle> {
  return await http.post({
    path: `${path}/${docId}/recipients`,
    body: { recipient, ...draftIncludes },
  });
}

export async function addRecipientsToDocument(
  docId: SendingRequest['id'],
  recipients: DocumentRecipientDto[],
): Promise<SendingRequestBundle> {
  return await http.post({
    path: `${path}/${docId}/recipients`,
    body: { recipients, ...draftIncludes },
  });
} // suggestedrecipients

export async function updateDocumentRecipient(
  docId: SendingRequest['id'],
  recipient: DocumentRecipientDto,
): Promise<SendingRequestBundle> {
  return await http.put({
    path: `${path}/${docId}/recipients`,
    body: { recipient, ...draftIncludes },
  });
}

export async function removeRecipientFromDocument(
  docId: SendingRequest['id'],
  recipient: DocumentRecipientDto,
): Promise<SendingRequestBundle> {
  return await http.destroyWithBody({
    path: `${path}/${docId}/recipients`,
    body: { recipient, ...draftIncludes },
  });
}

// eslint-disable-next-line sonarjs/no-identical-functions
export async function updateDocumentWithPostalRecipient(
  docId: SendingRequest['id'],
  recipient: CreatePostalRecipient,
): Promise<SendingRequestBundle> {
  return await http.post({
    path: `${path}/${docId}/recipients`,
    body: { recipient, ...draftIncludes },
  });
}

export async function updateDocumentWithDematRecipient(
  docId: SendingRequest['id'],
  patch: CreateDematRecipient,
): Promise<SendingRequestBundle> {
  return await http.post({
    path: `${path}/${docId}/recipients`,
    body: { recipient: patch, ...draftIncludes },
  });
}

export async function addPatientRecipientToDocument(
  docId: SendingRequest['id'],
  documentPatient: DocumentPatientDto,
  shouldRefresh = false,
): Promise<SendingRequestBundle> {
  return await http.put({
    path: `${path}/${docId}/patientRecipient`,
    body: { patient: documentPatient, shouldRefresh, ...draftIncludes },
  });
}

export async function removePatientRecipientFromDocument(
  docId: SendingRequest['id'],
): Promise<SendingRequestBundle> {
  return await http.destroyWithBody({
    path: `${path}/${docId}/patientRecipient`,
    body: { ...draftIncludes },
  });
}

// Patient

export async function createDocumentPatient(
  docId: SendingRequest['id'],
  patient: CreatePatient,
  shouldRefresh = false,
): Promise<SendingRequestBundle> {
  return await http.post({
    path: `${path}/${docId}/patient`,
    body: { patient, shouldRefresh, ...draftIncludes },
  });
}

// eslint-disable-next-line sonarjs/no-identical-functions
export async function addDocumentPatient(
  docId: SendingRequest['id'],
  patient: DocumentPatientDto,
  shouldRefresh = false,
): Promise<SendingRequestBundle> {
  return await http.post({
    path: `${path}/${docId}/patient`,
    body: { patient, shouldRefresh, ...draftIncludes },
  });
}

export async function removeDocumentPatient(
  docId: SendingRequest['id'],
): Promise<SendingRequestBundle> {
  return await http.destroyWithBody({
    path: `${path}/${docId}/patient`,
    body: { ...draftIncludes },
  });
}

export async function updateDocumentPatient(
  docId: SendingRequest['id'],
  patient: Patient,
  prevValues: Patient,
  skipGamUpdate = false,
) {
  return await http.put({
    path: `${path}/${docId}/patient`,
    body: { patient, prevValues, skipGamUpdate, ...draftIncludes },
  });
} // return patient, patient and needsRefresh

// Integrations

export async function toggleConnector({
  documentId,
  integrationId,
  connectorId,
  integrationMode,
}: {
  documentId: SendingRequest['id'];
} & ToggleConnector): Promise<SendingRequestBundle> {
  return await http.put({
    path: `${path}/${documentId}/integrations/${integrationId}/connectors/${connectorId}/toggle`,
    body: {
      integrationMode,
      integrationId,
      ...draftIncludes,
    },
  });
}

export async function updateConnectorSettings({
  documentId,
  integrationId,
  connectorId,
  settings,
}: {
  documentId: SendingRequest['id'];
  integrationId: Integration['id'];
  connectorId: DocumentConnector['id'];
  settings: DmpConnectorSettings;
}): Promise<SendingRequestBundle> {
  return await http.put({
    path: `${path}/${documentId}/integrations/${integrationId}/connectors/${connectorId}/settings`,
    body: { ...settings, ...draftIncludes },
  });
}

export async function toggleConnectorIntegrationMode({
  connectorId,
  documentId,
  fallbackReference,
  integrationId,
  integrationMode,
}: {
  connectorId: DocumentConnector['id'];
  documentId: SendingRequest['id'];
  fallbackReference: string;
  integrationId: Integration['id'];
  integrationMode: IntegrationMode;
}): Promise<SendingRequestBundle> {
  return await http.put({
    path: `${path}/${documentId}/integrations/${integrationId}/connectors/${connectorId}/toggle_fallback`,
    body: { fallbackReference, integrationMode, ...draftIncludes },
  });
}

export async function fetchEncounters(
  docId: SendingRequest['id'],
  integrationId: Integration['id'],
) {
  return await http.get({ path: `${path}/${docId}/integrations/${integrationId}/encounters` });
}

export async function replaceEncounter(
  docId: SendingRequest['id'],
  integrationId: Integration['id'],
  encounter: Encounter,
) {
  return await http.put({
    path: `${path}/${docId}/integrations/${integrationId}/encounters`,
    body: encounter as unknown as BodyType,
  });
}

export async function fetchEhrPatients(
  docId: SendingRequest['id'],
): Promise<List<EhrPatientIntegration>> {
  return await http.get({ path: `${path}/${docId}/integrations/patients` });
}

export async function updateEhrPatient(
  docId: SendingRequest['id'],
  patientId: string | undefined,
  organizationId: string,
): Promise<Integration> {
  return await http.put({
    path: `${path}/${docId}/integrations/patient`,
    body: { patientId, organizationId },
  });
}

export async function refreshPatientMatch(docId: SendingRequest['id']): Promise<undefined> {
  return await http.get({ path: `${path}/${docId}/integrations/refresh` });
}

// Actions on documents

export async function sendDocument(id: SendingRequest['id']): Promise<SendingRequestDto> {
  return await http.put({
    path: `${path}/${id}/send`,
    body: { ...draftIncludes },
  });
} // useless response payload

interface SendDocumentsResponse {
  id: string;
  success: boolean;
}
export async function sendDocuments(ids: SendingRequest['id'][]): Promise<SendDocumentsResponse[]> {
  return await http.post({
    path: `${path}/send`,
    body: { ids, ...draftIncludes },
  });
}

export async function downloadDocuments(
  ids: SendingRequest['id'][],
  dashboardType: SendingsDashboardType,
): Promise<void> {
  return await http.download({
    path: `${path}/download`,
    body: { ids, dashboardType },
  });
}

export async function previewDocuments(ids: SendingRequest['id'][]): Promise<DocumentsPreview> {
  return await http.get({
    path: `${path}/previews`,
    searchParams: { ids },
  });
}

export async function groupDocuments(ids: SendingRequest['id'][]): Promise<void> {
  return await http.post({
    path: `${path}/group`,
    body: { ids },
  });
}

export async function splitDocuments(ids: SendingRequest['id'][]): Promise<void> {
  return await http.destroyWithBody({
    path: `${path}/group`,
    body: { ids },
  });
}

export async function splitDocument(id: SendingRequest['id']): Promise<void> {
  return await http.destroy({
    path: `${path}/${id}/group`,
  });
}

export async function duplicateDocument(id: SendingRequest['id']): Promise<SendingRequestDto> {
  return await http.post({
    path: `${path}/${id}/duplicate`,
    body: { ...draftIncludes },
  });
}

export async function duplicateDocuments(ids: SendingRequest['id'][]): Promise<void> {
  return await http.post({
    path: `${path}/duplicate`,
    body: { ids },
  });
}

export const cancelSendingRequests = (ids: SendingRequest['id'][]): Promise<void> =>
  http.post({
    path: '/documents/cancel',
    body: { ids },
  });

export async function updateDocument(
  docId: SendingRequest['id'],
  patch: SendingRequestUpdateOperation,
): Promise<SendingRequestBundle> {
  return await http.put({ path: `${path}/${docId}`, body: { ...patch, ...draftIncludes } });
}

// Other

export async function fetchSuggestedRecipients(
  id: SendingRequest['id'],
): Promise<SuggestedRecipients> {
  return await http.get({
    path: `${path}/${id}/suggestedRecipients`,
  });
}

export async function setCustomEnsMessage(
  documentId: SendingRequest['id'],
  message: string,
): Promise<SendingRequestBundle> {
  return await http.post({
    path: `${path}/${documentId}/customPatientEnsMessage`,
    body: { message, ...draftIncludes },
  });
}

export async function setEnsConversationStatus(
  documentId: SendingRequest['id'],
  closed: boolean,
): Promise<SendingRequestBundle> {
  return await http.post({
    path: `${path}/${documentId}/ensConversationStatus`,
    body: { closed, ...draftIncludes },
  });
}

export async function removeVerification(data: RemoveVerificationPayload) {
  return await http.post<SendingRequestBundle>({
    path: `${path}/${data.id}/dismissVerification`,
    body: { verification_id: data.verification.id.toString(), ...draftIncludes },
  });
}

export async function fetchTotalReadyToSend(): Promise<CounterReadyToSend> {
  return await http.get({ path: `${path}/counter/readyToSendCounter` });
}

export async function fetchTotalDraftCounter(): Promise<CounterDraft> {
  return await http.get({ path: `${path}/counter/draftsCounter` });
}

// SENT

// Integrations

export async function cancelDmpDocument(
  documentId: SendingRequest['id'],
  integrationId: Integration['id'],
  connectorId: Connector['id'],
): Promise<SendingRequestBundle> {
  return await http.post({
    path: `${path}/${documentId}/integrations/${integrationId}/connectors/${connectorId}/cancel`,
    body: { ...sentIncludes },
  });
}

export async function replaceDmpDocument(
  documentId: SendingRequest['id'],
  integrationId: Integration['id'],
  connectorId: Connector['id'],
  file: File,
  settings: DmpConnectorSettings,
  userSettings: UserSettings,
): Promise<SendingRequestBundle> {
  const formData = new FormData();

  formData.append('file', file, file.name);
  formData.append('settings', JSON.stringify(settings));
  formData.append('userSettings', JSON.stringify(userSettings));

  return await http.postMultiFormData({
    path: `${path}/${documentId}/integrations/${integrationId}/connectors/${connectorId}/replace`,
    body: { ...formData, ...sentIncludes },
  });
}

// Other
export async function replaceMssDocument(
  documentId: SendingRequest['id'],
  file: File,
  telecoms: string[],
): Promise<SendingRequestBundle> {
  const formData = new FormData();

  formData.append('file', file, file.name);
  for (const telecom of telecoms) {
    formData.append('telecoms[]', telecom);
  }

  return await http.postMultiFormData({
    path: `${path}/${documentId}/replace`,
    body: { ...formData, ...sentIncludes },
  });
}

// ERRORS

export async function markDocumentAsRead(documentId: SendingRequest['id']) {
  await http.put({
    path: `${path}/${documentId}/read`,
  });
}

export async function markDocumentAsUnread(documentId: SendingRequest['id']) {
  await http.destroy({
    path: `${path}/${documentId}/read`,
  });
}

export async function markAllAsRead() {
  await http.put({
    path: `${path}/read`,
  });
}

export async function fetchErroredDocuments(
  searchParams: DashboardSearchParams,
): Promise<DocumentsList> {
  return await http.get({ path: `${path}/errored`, searchParams });
}

// FORWARD (Inbox and Sent)
export async function forwardDocument(
  documentId: SendingRequest['id'],
  dashboardType: SendingsDashboardType,
  userSettings: UserSettings,
  selectedIdentityReference?: string,
): Promise<{ document: SendingRequestBundle['document'] }> {
  return await http.post({
    path: `${path}/forward`,
    body: { documentId, selectedIdentityReference, dashboardType, userSettings, ...draftIncludes },
  });
}

// INBOX DASHBOARDS

export async function fetchDocument(
  id: SendingRequest['id'],
  dashboardType: SendingsDashboardType,
): Promise<SendingRequestBundle> {
  return await http.get({ path: `${path}/${id}`, searchParams: { dashboardType } });
}

export async function fetchDocuments(searchParams: DashboardSearchParams): Promise<DocumentsList> {
  return await http.get({ path, searchParams: parseSearchParams(searchParams) });
}

// INBOX

export async function closeEnsConversation(
  documentId: SendingRequest['id'],
): Promise<SendingRequestBundle> {
  return await http.post({
    path: `${path}/${documentId}/ensConversationStatus`,
    body: { closed: true },
  });
} // todo: update with specific inbox includes if necessary

export async function archiveDocument(id: SendingRequest['id']): Promise<void> {
  return await http.put({
    path: `${path}/${id}/archive`,
  });
}

export async function archiveDocuments(ids: SendingRequest['id'][]): Promise<void> {
  return await http.put({
    path: `${path}/archive`,
    body: { ids },
  });
}

// ARCHIVE

export async function restoreDocument(id: SendingRequest['id']): Promise<void> {
  return await http.put({
    path: `${path}/${id}/restore`,
  });
}

export async function restoreDocuments(ids: SendingRequest['id'][]): Promise<void> {
  return await http.put({
    path: `${path}/restore`,
    body: { ids },
  });
}
