import { http } from '@services/http/http.service';

import {
  Attachment,
  BodyType,
  CreateIntegrationRequest,
  CreatePatient,
  DashboardSearchParams,
  DocumentPatientDto,
  IntegrationRequest,
  IntegrationRequestBundle,
  IntegrationRequestDto,
  IntegrationRequestUpdateOperation,
  IntegrationRequestsList,
  IntegrationsDashboardType,
  Patient,
} from '@honestica/core-apps-common/types';

import { IntegrationRequestID } from '@store/documents/documents.state';
import { parseSearchParams } from '@utils/search.util';

const path = '/integrations';

export async function fetchIntegrationRequest(
  id: IntegrationRequest['id'],
  dashboardType: IntegrationsDashboardType,
): Promise<IntegrationRequestBundle> {
  return await http.get({ path: `${path}/${id}`, searchParams: { dashboardType } });
}

export async function fetchIntegrationRequestAttachments(
  id: IntegrationRequest['id'],
): Promise<Attachment[]> {
  return await http.get({ path: `${path}/${id}/attachments` });
}

export async function fetchIntegrationRequests(
  searchParams: DashboardSearchParams,
): Promise<IntegrationRequestsList> {
  return await http.get({ path, searchParams: parseSearchParams(searchParams) });
}

export async function integrateIntegrationRequests(
  ids: IntegrationRequestID[],
): Promise<IntegrationRequest[]> {
  return await http.post({
    path: `${path}/integrate`,
    body: { ids },
  });
}

export async function cancelIntegrationRequests(
  ids: IntegrationRequestID[],
): Promise<IntegrationRequest[]> {
  return await http.post({
    path: `${path}/cancel`,
    body: { ids },
  });
}

export async function cancelIntegrationRequest(
  id: IntegrationRequestID,
): Promise<IntegrationRequest> {
  return await http.put({
    path: `${path}/${id}/cancel`,
  });
}

export async function createIntegrationRequest(
  document: CreateIntegrationRequest,
): Promise<{ id: string }> {
  const formData = new FormData();
  const requestBody: Record<string, any> = {};

  for (const [key, value] of Object.entries(document)) {
    if (key === 'file') {
      if (document.file) formData.append('file', document.file, document.file.name);
    } else {
      requestBody[key] = value;
    }
  }

  formData.append('document', JSON.stringify(requestBody));

  return await http.postMultiFormData({
    path: `${path}`,
    body: formData,
  });
}

export async function removeIntegrationRequestPatient(
  docId: IntegrationRequest['id'],
): Promise<IntegrationRequestBundle> {
  return await http.destroy({ path: `${path}/${docId}/patient` });
}

export async function createIntegrationRequestPatient(
  docId: IntegrationRequestID,
  patient: CreatePatient,
  shouldRefresh = false,
): Promise<IntegrationRequestBundle> {
  return await http.post({ path: `${path}/${docId}/patient`, body: { patient, shouldRefresh } });
}

export async function addIntegrationRequestPatient(
  docId: IntegrationRequestID,
  patient: DocumentPatientDto,
  shouldRefresh = false,
): Promise<IntegrationRequestBundle> {
  return await http.post({ path: `${path}/${docId}/patient`, body: { patient, shouldRefresh } });
}

export async function updateIntegrationRequestPatient(
  docId: IntegrationRequestID,
  patient: Patient,
  prevValues: Patient,
) {
  return await http.put({ path: `${path}/${docId}/patient`, body: { patient, prevValues } });
}

export async function refreshIntegrationRequestPatientMatch(
  docId: IntegrationRequestID,
): Promise<undefined> {
  return await http.get({ path: `${path}/${docId}/integrations/refresh` });
}

export async function updateIntegrationRequest(
  docId: IntegrationRequestID,
  patch: IntegrationRequestUpdateOperation,
): Promise<IntegrationRequestBundle> {
  return await http.put({ path: `${path}/${docId}`, body: patch });
}

export type IntegrationRequestBulkUpdatePayload = {
  identityReference: string;
  updates: {
    uuid: string;
    documentType?: string;
    documentTitle?: string;
  }[];
};

export async function updateIntegrationRequests(payload: IntegrationRequestBulkUpdatePayload) {
  return await http.put({ path: `${path}/update_multiple`, body: payload as BodyType });
}

export async function downloadIntegrationRequest(id: string): Promise<void> {
  return await http.download({
    path: `${path}/download/${id}`,
  });
}

export async function downloadIntegrationRequests(ids: IntegrationRequestID[]): Promise<void> {
  return await http.download({
    path: `${path}/download`,
    body: { ids },
  });
}

export async function duplicateIntegrationRequest(
  id: IntegrationRequest['id'],
): Promise<IntegrationRequestDto> {
  return await http.post({
    path: `${path}/${id}/duplicate`,
  });
}

export async function duplicateIntegrationRequests(ids: IntegrationRequest['id'][]): Promise<void> {
  return await http.post({
    path: `${path}/duplicate`,
    body: { ids },
  });
}
